<template>
    <v-container
            fill-height
            fluid
            grid-list-xl
    >
        <v-layout
                justify-center
                wrap
        >
            <template v-if="loaded">
                <data-table ref="orgVoucherDataTable"
                            :key="'ManageVouchers'"
                            :api-end-point="apiurl"
                            :search="search"
                            :is-custom-filter="false"
                            :sort-order="sortOrder"
                            :table-name="'Manage Vouchers'"
                            :user_id="user_id"
                            :user_type="user_type"
                            :isDetail="true"
                            :is-add="true"
                            :pre-selected-filters="filters.selectedFilters"

                >
                    <template slot="datatable-actions">
                        <div class="action-buttons ml-3">
                            <button @click="createVoucherDialog = true" class="btn btn-primary">Create Voucher</button>
                        </div>
                    </template>

                    <template slot="filters">
                        <filters :filters="filters.voucherCodePlan"></filters>
                        <filters :filters="filters.voucherCodeOrganisation"></filters>
                        <filters :filters="filters.voucherCodeType"></filters>
                        <filters :filters="filters.getPlanTypeFilters"></filters>
                    </template>

                </data-table>
            </template>
        </v-layout>

        <create-voucher v-if="createVoucherDialog"
                        :visible="createVoucherDialog"
                        :actionType="actionType"
                        :value="selectedVoucher"
                        v-on:hide="createVoucherDialog = false"
        ></create-voucher>


    </v-container>
</template>

<script>

    import DataTable from "../../components/common/dataTable/DataTable";
    import CreateVoucher from "../../components/main/orgConsole/manageVouchers/CreateVoucher";
    import Filters from "../../components/common/dataTable/filters/Filters";

    export default {
        name: "ManageVouchers",
        components: {
            DataTable,
            CreateVoucher,
            Filters,
        },
        props: {
            user_id: {
                user_id: [Number, String]
            },
            user_type: {
                type: String
            },
        },
        data() {
            return {
                apiurl: 'partner_vouchers',
                search: {
                    placeholder: 'Search',
                    searchAble: false
                },
                sortOrder: [
                    {
                        field: 'id',
                        sortField: 'id',
                        direction: 'desc'
                    }
                ],
                filters: [],
                selected_filters: [],
                createVoucherDialog: false,
                detailUrl: 'manageVoucherCodes/',
                selectedVoucher: null,
                showAddVoucherModal: false,
                loaded: false,
                actionType: "create",
            }
        },
        mounted() {
        },
        created() {
            this.fetchFilters();
        },
        methods: {
            fetchFilters(){
                this.$http.get(this.url+'filters/manage_vouchers').then((res) => {
                    if(res.data) {
                        this.filters = res.data;
                        this.loaded = true;
                    } else {
                        alert('there is some problem');
                    }
                });
            },
        },
        events: {
            'open-details': function(data) {
                this.$router.push(this.detailUrl + data.id);
            },
            'add-record': function(data) {
                this.selectedVoucher = data;
                this.actionType = "add";
                this.createVoucherDialog = true;
            },
        }
    }
</script>

<style scoped>

</style>
